import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import IFamiglia from '../../types/IFamiglia';
import axiosInstance from '../../utils/axiosInstance';
import IDocumento from '../../types/IDocumento';

interface FamigliaState {
  famiglie: IFamiglia[];
  selectedFamiglia: IFamiglia | null;
  newFamiglia: IFamiglia | null
  loading: boolean;
  error: string | null;
}

const initialState: FamigliaState = {
  famiglie: [],
  selectedFamiglia: null,
  newFamiglia: null,
  loading: false,
  error: null,
};

// Fetch all famiglie
export const fetchFamiglie = createAsyncThunk(
  'famiglia/fetchFamiglie',
  async (searchTerm?: string) => {
    const response = await axiosInstance.get(`/famiglia${searchTerm ? `?s=${searchTerm}` : ''}`);
    return response.data;
  }
);

// Fetch all famiglie archiviate
export const fetchFamiglieArchived = createAsyncThunk(
  'famiglia/fetchFamiglieArchived',
  async (searchTerm?: string) => {
    const response = await axiosInstance.get(`/famiglia/archivio${searchTerm ? `?s=${searchTerm}` : ''}`);
    return response.data;
  }
);

// Fetch a single famiglia by id
export const fetchFamigliaById = createAsyncThunk(
  'famiglia/fetchFamigliaById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/famiglia/${id}`);
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero della famiglia');
    }
  }
);

// Create a new famiglia
export const createFamiglia = createAsyncThunk(
  'famiglia/createFamiglia',
  async (newFamiglia: IFamiglia, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/famiglia', newFamiglia);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante la creazione della famiglia');
    }
  }
);

// Update an existing famiglia
export const updateFamiglia = createAsyncThunk(
  'famiglia/updateFamiglia',
  async (
    { id, newFamiglia }: { id: number, newFamiglia: IFamiglia },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`/famiglia/${id}`, newFamiglia);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante l\'aggiornamento della famiglia');
    }
  }
);

// Delete an existing famiglia
export const deleteFamiglia = createAsyncThunk(
  'famiglia/deleteFamiglia',
  async (id: number) => {
    const response = await axiosInstance.delete(`/famiglia/${id}`);
    return response.data;
  }
);

// Archive an existing famiglia
export const archiveFamiglia = createAsyncThunk(
  'famiglia/archiveFamiglia',
  async (id: number) => {
    const response = await axiosInstance.put(`/famiglia/${id}/archive`);
    return response.data;
  }
);

// Unarchive an existing famiglia
export const unarchiveFamiglia = createAsyncThunk(
  'famiglia/unarchiveFamiglia',
  async (id: number) => {
    const response = await axiosInstance.put(`/famiglia/${id}/unarchive`);
    return response.data;
  }
);

// Azione per aggiornare la lista documenti nello stato di anagrafica
export const updateDocumentiList = createAsyncThunk(
  'famiglia/updateDocumentiList',
  async ({ idAnagrafica, documento, remove = false }: { idAnagrafica: number; documento: IDocumento; remove?: boolean }) => {
    return { idAnagrafica, documento, remove };
  }
);

const famigliaSlice = createSlice({
  name: 'famiglia',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //* Fetch all famiglie
      .addCase(fetchFamiglie.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFamiglie.fulfilled, (state, action) => {
        state.loading = false;
        state.famiglie = action.payload;
      })
      .addCase(fetchFamiglie.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch famiglie';
      })
      //* Fetch all famiglie archived
      .addCase(fetchFamiglieArchived.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFamiglieArchived.fulfilled, (state, action) => {
        state.loading = false;
        state.famiglie = action.payload;
      })
      .addCase(fetchFamiglieArchived.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch famiglie archived';
      })
      //* Fetch a single famiglia by id
      .addCase(fetchFamigliaById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFamigliaById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedFamiglia = action.payload;
      })
      .addCase(fetchFamigliaById.rejected, (state, action) => {
        state.loading = false;
        state.selectedFamiglia = null;
        state.error = action.payload as string || 'Failed to fetch the famiglia';
      })
      //* Create a new famiglia
      .addCase(createFamiglia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createFamiglia.fulfilled, (state, action) => {
        state.loading = false;
        state.newFamiglia = action.payload;
      })
      .addCase(createFamiglia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string || 'Failed to create famiglia';
      })
      //* Update an existing famiglia
      .addCase(updateFamiglia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFamiglia.fulfilled, (state, action) => {
        state.loading = false;
        // state.selectedFamiglia = action.payload;
      })
      .addCase(updateFamiglia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string || 'Failed to update famiglia';
      })
      //* Delete an existing famiglia
      .addCase(deleteFamiglia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFamiglia.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedFamiglia = null;
      })
      .addCase(deleteFamiglia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to delete famiglia';
      })
      //* Archive an existing famiglia
      .addCase(archiveFamiglia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(archiveFamiglia.fulfilled, (state, action) => {
        state.loading = false;
        // state.selectedFamiglia = action.payload;
      })
      .addCase(archiveFamiglia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to archive famiglia';
      })
      //* Unarchive an existing famiglia
      .addCase(unarchiveFamiglia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unarchiveFamiglia.fulfilled, (state, action) => {
        state.loading = false;
        // state.selectedFamiglia = action.payload;
      })
      .addCase(unarchiveFamiglia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to archive famiglia';
      })
      //* Aggiorna lista documenti
      .addCase(updateDocumentiList.fulfilled, (state, action) => {
        const { idAnagrafica, documento, remove } = action.payload;
        if (state.selectedFamiglia && state.selectedFamiglia.Anagrafiche) {
          state.selectedFamiglia.Anagrafiche.forEach(anagrafica => {
            if (anagrafica.Id === idAnagrafica) {
              if (remove) {
                anagrafica.Documenti = anagrafica.Documenti?.filter(doc => doc.Id !== documento.Id);
              } else {
                anagrafica.Documenti?.push(documento);
              }
            }
          });
        }
      });
  },
});

export default famigliaSlice.reducer;
