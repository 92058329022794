import React from 'react';
import Anagrafiche from '../components/Famiglie';

function Home() {
  return (
    <div className=''>
        <Anagrafiche />
    </div>
  );
}

export default Home;
