import React, { useEffect, useState } from 'react';
import { MdAdd, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import AgendaNavbar from '../components/AgendaNavbar';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { createGiornata, fetchGiornataByData } from '../redux/slices/agendaSlice';
import Loading from '../components/utils/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import IGiornata, { exampleGiornata } from '../types/IGiornata';
import ITerapia, { exampleTerapia } from '../types/ITerapia';
import { todayStr } from '../utils/functions';
import { fetchFamigliaById } from '../redux/slices/famigliaSlice';
import { addNotification } from '../redux/slices/notificationSlice';
import { MessageType, UscitaType } from '../types';
import IUscita, { exampleUscita } from '../types/IUscita';
import { FaRegStar, FaStar } from 'react-icons/fa6';

function Giornata() {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { date } = useParams<{ date: string }>();
    const dispatch = useAppDispatch();
    const { selectedGiornata, loading, error } = useAppSelector((state) => state.agenda);
    const { selectedFamiglia, loading: loadingFamiglia, error: errorFamiglia } = useAppSelector((state) => state.famiglia);

    const initialFormData: IGiornata = {
        ...exampleGiornata,
        Id_Famiglia: Number(id),
        Data: date ?? '',
    };

    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState<IGiornata>(initialFormData);

    useEffect(() => {
        setFormData(initialFormData);
        if (id && date) {
            dispatch(fetchGiornataByData({ id: Number(id), date }));
        }
    }, [id, date, dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(fetchFamigliaById(Number(id)));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (selectedGiornata && !error) {
            setFormData(selectedGiornata);
            console.log('selectedGiornata', selectedGiornata);
        }
    }, [selectedGiornata, error]);

    useEffect(() => {
        if (error) {
            if (!error.includes('non trovata')) {
                dispatch(addNotification({ message: error, type: MessageType.ERROR }));
            } else {
                if (selectedFamiglia?.IsArchiviato) {
                    dispatch(addNotification({ message: 'Famiglia archiviata, non è possibile creare una nuova giornata', type: MessageType.WARNING }));
                }
                setFormData(initialFormData);
                setIsEditing(true);
            }
        } else {
            setIsEditing(false);
        }
    }, [error]);

    const addTerapia = () => {
        setFormData((prevData) => ({
            ...prevData,
            Terapie: [
                ...(prevData.Terapie || []),
                exampleTerapia
            ]
        }));
    };

    const removeTerapia = (index: number) => {
        setFormData((prevData) => ({
            ...prevData,
            Terapie: prevData.Terapie?.filter((_, i) => i !== index) || []
        }));
    };

    const addUscita = () => {
        setFormData((prevData) => ({
            ...prevData,
            Uscite: [
                ...(prevData.Uscite || []),
                exampleUscita
            ]
        }));
    };

    const removeUscita = (index: number) => {
        setFormData((prevData) => ({
            ...prevData,
            Uscite: prevData.Uscite?.filter((_, i) => i !== index) || []
        }));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue: string | null = value;

        // Dividi il nome per individuare la struttura annidata, se presente
        const nameParts = name.split('.'); // Es: 'Sonno.Sveglia'

        setFormData((prevData) => {
            // Clona l'oggetto corrente di formData
            const updatedData = { ...prevData };

            // Naviga attraverso l'oggetto fino all'ultimo campo
            let currentLevel: any = updatedData;
            for (let i = 0; i < nameParts.length - 1; i++) {
                currentLevel[nameParts[i]] = { ...currentLevel[nameParts[i]] }; // Clona ogni livello per evitare la mutazione dello stato originale
                currentLevel = currentLevel[nameParts[i]];
            }

            if (formattedValue === '') {
                formattedValue = null;
            }

            // Aggiorna il valore del campo specifico
            currentLevel[nameParts[nameParts.length - 1]] = formattedValue;

            return updatedData;
        });
    };

    const handleTerapiaChange = (index: number, field: keyof ITerapia, value: string) => {
        let formattedValue: string | null = value;
        if (formattedValue === '') {
            formattedValue = null;
        }
        setFormData((prevData) => {
            const updatedTerapie = [...(prevData.Terapie || [])];
            updatedTerapie[index] = { ...updatedTerapie[index], [field]: formattedValue };
            return { ...prevData, Terapie: updatedTerapie };
        });
    };

    const handleUscitaChange = (index: number, field: keyof IUscita, value: string) => {
        let formattedValue: string | null = value;
        if (formattedValue === '') {
            formattedValue = null;
        }
        setFormData((prevData) => {
            const updatedUscite = [...(prevData.Uscite || [])];
            updatedUscite[index] = { ...updatedUscite[index], [field]: formattedValue };
            return { ...prevData, Uscite: updatedUscite };
        });
    };

    const handleIsPreferitoChange = () => {
        setFormData((prevData) => ({
            ...prevData,
            FattiSignificativi: {
                ...prevData.FattiSignificativi,
                IsPreferito: !prevData.FattiSignificativi.IsPreferito
            }
        }));
    };

    const handleSave = () => {
        if (formData.Uscite.some(uscita => uscita.Data && !uscita.Responsabile)) {
            dispatch(addNotification({ message: "Inserire il responsabile per l'uscita", type: MessageType.WARNING, tag: 'createGiornata' }));
            return;
        }
        if (formData.Uscite.some(uscita => uscita.DataRientro && !uscita.ResponsabileRientro)) {
            dispatch(addNotification({ message: "Inserire il responsabile per il rientro", type: MessageType.WARNING, tag: 'createGiornata' }));
            return;
        }

        dispatch(createGiornata(formData))
            .then((result) => {
                if (createGiornata.fulfilled.match(result)) {
                    setIsEditing(false);
                    dispatch(addNotification({ message: 'Giornata salvata', type: MessageType.SUCCESS, tag: 'createGiornata' }));
                } else {
                    setIsEditing(true);
                }
            })
            .catch(() => dispatch(addNotification({ message: 'Errore durante la creazione', type: MessageType.ERROR, tag: 'createGiornata' })));
    };

    return (
        <div className='container'>
            {loading && <Loading />}

            {!loading && (selectedGiornata || isEditing) && (
                <>
                    <AgendaNavbar isEditing={isEditing} setIsEditing={setIsEditing} handleSave={handleSave} />

                    <section id='uscite' className='bg-white border border-gray-300 rounded-2xl rounded-tl-none shadow-md p-6 pb-8 relative z-[97]'>
                        <h2 className='h2'>Uscite</h2>
                        {formData.Uscite.map((uscita, index) => (
                            <div key={index}>
                                <div className='flex items-center justify-between gap-10 mt-6'>
                                    <h3 className='h3 mb-4'>Uscita {index + 1}</h3>
                                    <div className='form-element'>
                                        <button className='btn btn-danger' onClick={() => removeUscita(index)} disabled={!isEditing}><MdDelete size={18} /></button>
                                    </div>
                                </div>
                                <div className='grid grid-cols-6 gap-x-10 gap-y-4'>
                                    <div className='col-span-3'>
                                        <div className='form-element'>
                                            <label htmlFor='tipologia'>Tipologia</label>
                                            <select id='tipologia' disabled={!isEditing} value={uscita.Tipologia ?? ''} onChange={(e) => handleUscitaChange(index, 'Tipologia', e.target.value)}>
                                                <option value='' hidden></option>
                                                <option value={UscitaType.ORDINARIA}>Ordinaria</option>
                                                <option value={UscitaType.EXTRA}>Extra</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-span-3'>
                                        <div className='form-element'>
                                            <label htmlFor='motivo'>Motivo</label>
                                            <input
                                                type='text'
                                                id='motivo'
                                                disabled={!isEditing}
                                                value={uscita.Motivo ?? ''}
                                                onChange={(e) => handleUscitaChange(index, 'Motivo', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='form-element'>
                                            <label htmlFor='uscita_data'>Data</label>
                                            <input type='date'
                                                id='uscita_data'
                                                disabled={!isEditing}
                                                value={uscita.Data ?? ''}
                                                max={todayStr()}
                                                onChange={(e) => handleUscitaChange(index, 'Data', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='form-element'>
                                            <label htmlFor='uscita_orario'>Orario</label>
                                            <input type='time'
                                                id='uscita_orario'
                                                disabled={!isEditing}
                                                value={uscita.Ora ?? ''}
                                                onChange={(e) => handleUscitaChange(index, 'Ora', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='form-element'>
                                            <label htmlFor='uscita_responsabile'>Responsabile</label>
                                            <input type='text'
                                                id='uscita_responsabile'
                                                disabled={!isEditing}
                                                value={uscita.Responsabile ?? ''}
                                                onChange={(e) => handleUscitaChange(index, 'Responsabile', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h3 className='h3 mb-4 mt-4'>Rientro {index + 1}</h3>
                                <div className='grid grid-cols-6 gap-x-10 gap-y-4'>
                                    <div className='col-span-2'>
                                        <div className='form-element'>
                                            <label htmlFor='rientro_data'>Data</label>
                                            <input type='date'
                                                id='rientro_data'
                                                disabled={!isEditing}
                                                value={uscita.DataRientro ?? ''}
                                                max={todayStr()}
                                                onChange={(e) => handleUscitaChange(index, 'DataRientro', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='form-element'>
                                            <label htmlFor='rientro_orario'>Orario</label>
                                            <input type='time'
                                                id='rientro_orario'
                                                disabled={!isEditing}
                                                value={uscita.OraRientro ?? ''}
                                                onChange={(e) => handleUscitaChange(index, 'OraRientro', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='form-element'>
                                            <label htmlFor='rientro_responsabile'>Responsabile</label>
                                            <input type='text'
                                                id='rientro_responsabile'
                                                disabled={!isEditing}
                                                value={uscita.ResponsabileRientro ?? ''}
                                                onChange={(e) => handleUscitaChange(index, 'ResponsabileRientro', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='w-1/3 mt-6'>
                            <div className='form-element'>
                                <button onClick={addUscita} disabled={!isEditing || selectedFamiglia?.IsArchiviato}>
                                    Aggiungi uscita <MdAdd size={18} />
                                </button>
                            </div>
                        </div>
                    </section>

                    <section id='terapie' className='bg-white border border-gray-300 rounded-2xl shadow-md p-6 pb-8 -mt-2 relative z-[98]'>
                        <h2 className='h2 mb-4'>Terapie</h2>
                        {formData.Terapie.map((terapia, index) => (
                            <div key={index} className="grid grid-cols-10 gap-x-10 gap-y-4 mb-6">
                                <div className='col-span-4'>
                                    <div className='form-element'>
                                        <label htmlFor={`tipologia_${index}`}>Tipologia</label>
                                        <input type='text'
                                            id={`tipologia_${index}`}
                                            disabled={!isEditing}
                                            value={terapia.Tipologia || ''}
                                            onChange={(e) => handleTerapiaChange(index, 'Tipologia', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-span-3'>
                                    <div className='form-element'>
                                        <label htmlFor={`inizio_${index}`}>Inizio</label>
                                        <input type='time'
                                            id={`inizio_${index}`}
                                            disabled={!isEditing}
                                            value={terapia.Inizio || ''}
                                            onChange={(e) => handleTerapiaChange(index, 'Inizio', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-span-3'>
                                    <div className='form-element'>
                                        <label htmlFor={`termine_${index}`}>Termine</label>
                                        <input type='time'
                                            id={`termine_${index}`}
                                            disabled={!isEditing}
                                            value={terapia.Termine || ''}
                                            onChange={(e) => handleTerapiaChange(index, 'Termine', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-span-9'>
                                    <div className='form-element'>
                                        <label htmlFor={`terapie_note_${index}`}>Note</label>
                                        <input type='text'
                                            id={`terapie_note_${index}`}
                                            disabled={!isEditing}
                                            value={terapia.Note || ''}
                                            onChange={(e) => handleTerapiaChange(index, 'Note', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-span-1'>
                                    <div className='form-element'>
                                        <button className='btn btn-danger' onClick={() => removeTerapia(index)} disabled={!isEditing}><MdDelete size={18} /></button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='w-1/3'>
                            <div className='form-element'>
                                <button onClick={addTerapia} disabled={!isEditing || selectedFamiglia?.IsArchiviato}>
                                    Aggiungi terapia <MdAdd size={18} />
                                </button>
                            </div>
                        </div>
                    </section>

                    <section id='fatti-significativi' className='bg-white border border-gray-300 rounded-2xl shadow-md p-6 pb-8 -mt-2 relative z-[99]'>
                        <div className='w-full flex items-center justify-between gap-10'>
                            <h2 className='h2 mb-4'>Fatti significativi</h2>
                            <div className={`${(!isEditing || selectedFamiglia?.IsArchiviato) ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={handleIsPreferitoChange}>
                                {formData.FattiSignificativi.IsPreferito ? (
                                    <FaStar size={24} className='text-yellow-500' />
                                ) : (
                                    <FaRegStar size={24} className='text-yellow-500' />
                                )}
                            </div>
                        </div>
                        <div className='grid grid-cols-3 gap-x-10 gap-y-4'>
                            <div className='col-span-3'>
                                <div className='form-element !grid !grid-cols-8'>
                                    <label htmlFor='mattina'>Mattina</label>
                                    <input type='text'
                                        id='mattina'
                                        name='FattiSignificativi.Mattina'
                                        className='col-span-7'
                                        disabled={!isEditing || selectedFamiglia?.IsArchiviato}
                                        value={formData.FattiSignificativi.Mattina ?? ''} onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-span-3'>
                                <div className='form-element !grid !grid-cols-8'>
                                    <label htmlFor='pomeriggio'>Pomeriggio</label>
                                    <input type='text'
                                        id='pomeriggio'
                                        name='FattiSignificativi.Pomeriggio'
                                        className='col-span-7'
                                        disabled={!isEditing || selectedFamiglia?.IsArchiviato}
                                        value={formData.FattiSignificativi.Pomeriggio ?? ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-span-3'>
                                <div className='form-element !grid !grid-cols-8'>
                                    <label htmlFor='sera'>Sera</label>
                                    <input type='text'
                                        id='sera'
                                        name='FattiSignificativi.Sera'
                                        className='col-span-7'
                                        disabled={!isEditing || selectedFamiglia?.IsArchiviato}
                                        value={formData.FattiSignificativi.Sera ?? ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="flex items-center justify-between mt-4">

                        <button className='btn btn-lg' disabled={isEditing || selectedFamiglia?.IsArchiviato} onClick={() => setIsEditing(!isEditing)}>
                            Modifica <MdEdit size={20} />
                        </button>

                        <button className='btn btn-lg' disabled={!isEditing || selectedFamiglia?.IsArchiviato} onClick={handleSave}>
                            Salva <MdSave size={20} />
                        </button>

                    </div>

                </>
            )}

        </div>
    );
}

export default Giornata;
