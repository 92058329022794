import React, { useEffect, useMemo, useState } from 'react';
import { MdDownload, MdEdit, MdEditDocument, MdSave } from 'react-icons/md';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { fetchFamigliaById } from '../redux/slices/famigliaSlice';
import { todayStr } from '../utils/functions';
import FormDownloadStoricoPopup from './popup/FormDownloadStoricoPopup';
import SearchBar from './utils/SearchBar';

interface IProps {
    isEditing?: boolean;
    setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
    isCreating?: boolean;
    handleSave?: () => void;
    searchTerm?: string;
    setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
}

function AgendaNavbar(props: IProps) {
    const { id } = useParams<{ id: string }>();
    const { date } = useParams<{ date: string }>();
    const { selectedFamiglia } = useAppSelector((state) => state.famiglia);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [activeTab, setActiveTab] = useState('Storico');
    const [showDownloadForm, setShowDownloadForm] = useState(false);

    const tabs = useMemo(() => {
        const baseTabs = [{ name: 'Dati anagrafici', path: `/famiglia/${id}` }];

        // if (!selectedFamiglia?.IsEducatore) { // TODO: adattare alla famiglia
        return [
            { name: 'Giornata', path: `/giornata/${id}/${todayStr()}` },
            { name: 'Storico', path: `/storico/${id}` },
            ...baseTabs,
        ];
        // }

        return baseTabs;
    }, [id, selectedFamiglia]);

    // Imposto l'activeTab in base alla rotta corrente
    useEffect(() => {
        // Ottieni la prima parte della rotta (ad esempio '/giornata', '/storico')
        const currentPath = location.pathname.split('/')[1];
        const matchedTab = tabs.find(tab => tab.path.includes(currentPath));

        if (matchedTab) {
            setActiveTab(matchedTab.name);
        }
    }, [location.pathname, tabs]);

    useEffect(() => {
        if (!selectedFamiglia && id) {
            // Effettua la fetch solo se non esiste già una famiglia selezionata
            dispatch(fetchFamigliaById(Number(id)));
        }
    }, [selectedFamiglia, id, dispatch]);

    const handleSave = () => {
        if (props.handleSave) {
            props.handleSave();
        }
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = event.target.value;
        if (date) {
            navigate(location.pathname.replace(date, selectedDate));
        }
    };

    return (
        <>
            {showDownloadForm && (
                <FormDownloadStoricoPopup
                    message='Seleziona il periodo da scaricare'
                    onClose={() => setShowDownloadForm(false)}
                />
            )}

            <div className='flex items-end justify-between mb-6'>
                <h1 className='h1'>
                    {props.isCreating ? 'Nuova famiglia' : (
                        selectedFamiglia ? `Famiglia ${selectedFamiglia.Cognome}` : 'Caricamento...')}
                </h1>
                {date && (
                    <div className='flex items-center gap-4'>
                        <span className='text-lg font-medium uppercase'>Data</span>
                        <div className="form-element text-lg font-medium">
                            <input type="date" value={date} max={todayStr()} onChange={handleDateChange} />
                        </div>
                    </div>
                )}
            </div>
            <div className='flex items-center justify-between'>
                <div className='flex items-center gap-5'>
                    {tabs.map((tab) => (
                        <Link
                            to={tab.path}
                            key={tab.name}
                            onClick={() => setActiveTab(tab.name)}
                            className={`px-4 py-2 ${id ? 'cursor-pointer' : 'pointer-events-none'} border border-b-0 border-gray-300 rounded-t-2xl relative ${activeTab === tab.name ? 'bg-white' : `bg-gray-100 ${id ? 'hover:bg-white' : ''}`}`}
                        >
                            {tab.name}
                            {activeTab === tab.name && (
                                <div className='absolute -bottom-1 left-0 w-full h-2 bg-white z-[800]'></div>
                            )}
                        </Link>
                    ))}
                </div>

                {activeTab === 'Giornata' && (
                    props.isEditing ? (
                        <button className='btn' disabled={selectedFamiglia?.IsArchiviato} onClick={handleSave}>Salva <MdSave /></button>
                    ) : (
                        <button className='btn' disabled={selectedFamiglia?.IsArchiviato} onClick={() => props.setIsEditing && props.setIsEditing(!props.isEditing)}>Modifica <MdEdit /></button>
                    )
                )}

                {activeTab === 'Storico' && (
                    <div className='flex items-center gap-4'>
                        <SearchBar value={props.searchTerm ?? ''} onChange={props.setSearchTerm ?? (() => { })} />
                        <Link to={`/giornata/${id}/${todayStr()}`} className={`btn ${selectedFamiglia?.IsArchiviato && ' disabled'}`}>
                            Giornata <MdEditDocument size={18} />
                        </Link>
                        <button className='btn btn-sm btn-outline' onClick={() => setShowDownloadForm(true)}><MdDownload size={20} /></button>
                    </div>
                )}
            </div>
        </>
    );
}

export default AgendaNavbar;
