import React, { useEffect, useState } from 'react';
import { MdEdit, MdSave } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { fetchAccount, updateAccount } from '../redux/slices/accountSlice';
import IAccount, { exampleAccount } from '../types/IAccount';
import Loading from '../components/utils/Loading';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { isValidCF, isValidPassword } from '../utils/functions';
import { addNotification } from '../redux/slices/notificationSlice';
import { MessageType, PasswordType, UserSex } from '../types';
import { updateAnagrafica } from '../redux/slices/anagraficaSlice';

function Profilo() {
    const dispatch = useAppDispatch();
    const { selectedAccount, loading, error } = useAppSelector((state) => state.account);
    const { loading: loadingAnagrafica, error: errorAnagrafica } = useAppSelector((state) => state.anagrafica);

    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState<IAccount>(exampleAccount);
    const [showPassword, setShowPassword] = useState<Record<PasswordType, boolean>>({
        [PasswordType.OLD]: false,
        [PasswordType.NEW]: false,
        [PasswordType.CONFIRM]: false,
    });

    useEffect(() => {
        dispatch(fetchAccount());
    }, []);

    useEffect(() => {
        if (selectedAccount && !error) {
            setFormData(selectedAccount);
        }
    }, [selectedAccount, error]);

    useEffect(() => {
        if (error) {
            dispatch(addNotification({ message: error, type: MessageType.ERROR }));
        }
    }, [error]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Dividi il nome per individuare la struttura annidata, se presente
        const nameParts = name.split('.'); // Es: 'Sonno.Sveglia'

        setFormData((prevData) => {
            // Clona l'oggetto corrente di formData
            const updatedData = { ...prevData };
            const name = nameParts[nameParts.length - 1];

            // Naviga attraverso l'oggetto fino all'ultimo campo
            let currentLevel: any = updatedData;
            for (let i = 0; i < nameParts.length - 1; i++) {
                currentLevel[nameParts[i]] = { ...currentLevel[nameParts[i]] }; // Clona ogni livello per evitare la mutazione dello stato originale
                currentLevel = currentLevel[nameParts[i]];
            }

            if (name === 'Nome' || name === 'Cognome') {
                formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
            }
            if (name === 'CF') {
                formattedValue = value.toUpperCase();
            }

            // Aggiorna il valore del campo specifico
            currentLevel[name] = formattedValue;

            return updatedData;
        });
    };

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue = value;

        setFormData({
            ...formData,
            [name]: formattedValue,
        });
    };

    const handleShowPassword = (field: PasswordType) => {
        setShowPassword((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const handleSavePassword = () => {
        if (!formData.Password) {
            dispatch(addNotification({ message: "Inserisci la password attuale", type: MessageType.WARNING, tag: 'savePassword' }));
            return;
        }
        if (!formData.NewPassword) {
            dispatch(addNotification({ message: "Inserisci la nuova password", type: MessageType.WARNING, tag: 'savePassword' }));
            return;
        }
        if (!formData.NewPasswordConfirm) {
            dispatch(addNotification({ message: "Conferma la nuova password", type: MessageType.WARNING, tag: 'savePassword' }));
            return;
        }
        if (!isValidPassword(formData.NewPassword)) {
            dispatch(addNotification({ message: "La nuova password non rispetta i requisiti minimi: almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale, per un totale di almeno 8 caratteri", type: MessageType.WARNING, tag: 'savePassword' }));
            return;
        }
        if (formData.NewPassword !== formData.NewPasswordConfirm) {
            dispatch(addNotification({ message: "La nuova password non corrisponde con la conferma", type: MessageType.WARNING, tag: 'savePassword' }));
            return;
        }
        dispatch(updateAccount(formData))
            .then((result) => {
                if (updateAccount.fulfilled.match(result)) {
                    setFormData({
                        ...formData,
                        Password: '',
                        NewPassword: '',
                        NewPasswordConfirm: '',
                    });
                    dispatch(addNotification({ message: "Password aggiornata", type: MessageType.SUCCESS, tag: 'savePassword' }));
                }
                else {
                    dispatch(addNotification({ message: "Errore durante l'aggiornamento", type: MessageType.ERROR, tag: 'savePassword' }));
                }
            })
            .catch(() => dispatch(addNotification({ message: "Errore durante l'aggiornamento", type: MessageType.ERROR, tag: 'savePassword' })));
    };

    const handleSaveAnagrafica = () => {
        if (!formData.Anagrafica.Nome || !formData.Anagrafica.Cognome || !formData.Anagrafica.DataNascita || !formData.Anagrafica.CF || !formData.Anagrafica.Sesso || !formData.Anagrafica.Residenza) {
            dispatch(addNotification({ message: "Compila tutti i campi", type: MessageType.WARNING, tag: 'saveAnagrafica' }));
            return;
        }
        if (!isValidCF(formData.Anagrafica.CF)) {
            dispatch(addNotification({ message: "Il Codice Fiscale non è valido", type: MessageType.WARNING, tag: 'saveAnagrafica' }));
            return;
        }

        dispatch(updateAnagrafica({ id: formData.Anagrafica.Id, newAnagrafica: formData.Anagrafica }))
            .then((result) => {
                if (updateAnagrafica.fulfilled.match(result)) {
                    setIsEditing(false);
                    dispatch(addNotification({ message: "Profilo aggiornato", type: MessageType.SUCCESS, tag: 'saveAnagrafica' }));
                }
                else {
                    dispatch(addNotification({ message: "Errore durante l'aggiornamento", type: MessageType.ERROR, tag: 'saveAnagrafica' }));
                }
            })
            .catch(() => dispatch(addNotification({ message: "Errore durante l'aggiornamento", type: MessageType.ERROR, tag: 'saveAnagrafica' })));
    };

    return (
        <div className='container'>

            <div className='grid grid-cols-5 gap-10'>

                <section className='col-span-3 flex flex-col gap-4 bg-white border border-gray-300 rounded-2xl shadow-md p-6'>

                    <h2 className='h2'>Dati anagrafici</h2>

                    {(loading || loadingAnagrafica) && <Loading height='300px' />}

                    {!loading && !loadingAnagrafica && !error && (
                        <>
                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='nome'>Nome</label>
                                <input type='text'
                                    id='nome'
                                    name='Anagrafica.Nome'
                                    className='col-span-3'
                                    disabled={!isEditing}
                                    value={formData.Anagrafica.Nome}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='cognome'>Cognome</label>
                                <input type='text'
                                    id='cognome'
                                    name='Anagrafica.Cognome'
                                    className='col-span-3'
                                    disabled={!isEditing}
                                    value={formData.Anagrafica.Cognome}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='data_nascita'>Data Nascita</label>
                                <input type='date'
                                    id='data_nascita'
                                    name='Anagrafica.DataNascita'
                                    className='col-span-3'
                                    disabled={!isEditing}
                                    value={formData.Anagrafica.DataNascita}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='cf'>Codice Fiscale</label>
                                <input type='text'
                                    id='cf'
                                    name='Anagrafica.CF'
                                    className='col-span-3'
                                    disabled={!isEditing}
                                    value={formData.Anagrafica.CF}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='sesso'>Sesso</label>
                                <select id='sesso' name='Anagrafica.Sesso' className='col-span-3' disabled={!isEditing} value={formData.Anagrafica.Sesso} onChange={handleChange}                                     >
                                    <option value={UserSex.NONE} hidden></option>
                                    <option value={UserSex.MALE}>Maschio</option>
                                    <option value={UserSex.FEMALE}>Femmina</option>
                                </select>
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='residenza'>Residenza</label>
                                <input type='text'
                                    id='residenza'
                                    name='Anagrafica.Residenza'
                                    className='col-span-3'
                                    disabled={!isEditing}
                                    value={formData.Anagrafica.Residenza}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-element !grid !grid-cols-4 gap-4'>
                                <label htmlFor='email'>Email</label>
                                <input type='email' id='email' name='Email' className='col-span-3' disabled title='Campo non modificabile' value={formData.Email} />
                            </div>
                        </>
                    )}

                    {selectedAccount && (
                        <div className='flex items-center justify-center mt-4'>

                            {isEditing ? (
                                <button className='btn btn-lg' onClick={handleSaveAnagrafica}>
                                    Salva <MdSave size={20} />
                                </button>
                            ) : (
                                <button className='btn btn-lg' onClick={() => setIsEditing(true)}>
                                    Modifica <MdEdit size={20} />
                                </button>
                            )}

                        </div>
                    )}

                </section>

                <section className='col-span-2 flex flex-col gap-4 bg-white border border-gray-300 rounded-2xl shadow-md p-6'>

                    <h2 className='h2'>Modifica password</h2>

                    <div className='form-element !grid !grid-cols-2 gap-4 relative'>
                        <label htmlFor='password_attuale'>Password attuale</label>
                        <input type={showPassword.old ? 'text' : 'password'} id='password_attuale' name='Password' value={formData.Password ?? ''} onChange={handleChangePassword} autoComplete='new-password' />
                        <span className='absolute right-3 cursor-pointer' onClick={() => handleShowPassword(PasswordType.OLD)}>
                            {showPassword.old ? <FaEye /> : <FaEyeSlash />}
                        </span>
                    </div>

                    <div className='form-element !grid !grid-cols-2 gap-4 relative'>
                        <label htmlFor='nuova_password'>Nuova Password</label>
                        <input type={showPassword.new ? 'text' : 'password'} id='nuova_password' name='NewPassword' value={formData.NewPassword ?? ''} onChange={handleChangePassword} />
                        <span className='absolute right-3 cursor-pointer' onClick={() => handleShowPassword(PasswordType.NEW)}>
                            {showPassword.new ? <FaEye /> : <FaEyeSlash />}
                        </span>
                    </div>

                    <div className='form-element !grid !grid-cols-2 gap-4 relative'>
                        <label htmlFor='conferma_password'>Conferma Password</label>
                        <input type={showPassword.confirm ? 'text' : 'password'} id='conferma_password' name='NewPasswordConfirm' value={formData.NewPasswordConfirm ?? ''} onChange={handleChangePassword} />
                        <span className='absolute right-3 cursor-pointer' onClick={() => handleShowPassword(PasswordType.CONFIRM)}>
                            {showPassword.confirm ? <FaEye /> : <FaEyeSlash />}
                        </span>
                    </div>

                    <div className='flex items-center justify-center mt-4'>

                        <button className='btn btn-lg' disabled={!formData.Password || !formData.NewPassword || !formData.NewPasswordConfirm} onClick={handleSavePassword}>Salva <MdSave size={20} /></button>

                    </div>

                </section>

            </div>

        </div>
    );
}

export default Profilo;
