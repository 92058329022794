import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import IAnagrafica from '../../types/IAnagrafica';
import axiosInstance from '../../utils/axiosInstance';
import IDocumento from '../../types/IDocumento';

interface AnagraficaState {
  loading: boolean;
  error: string | null;
}

const initialState: AnagraficaState = {
  loading: false,
  error: null,
};

// Update an existing anagrafica
export const updateAnagrafica = createAsyncThunk(
  'anagrafica/updateAnagrafica',
  async (
    { id, newAnagrafica }: { id: number, newAnagrafica: IAnagrafica },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`/anagrafica/${id}`, newAnagrafica);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante l\'aggiornamento della anagrafica');
    }
  }
);

const anagraficaSlice = createSlice({
  name: 'anagrafica',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //* Update an existing anagrafica
      .addCase(updateAnagrafica.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAnagrafica.fulfilled, (state, action) => {
        state.loading = false;
        // state.selectedAnagrafica = action.payload;
      })
      .addCase(updateAnagrafica.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string || 'Failed to update anagrafica';
      });
  },
});

export default anagraficaSlice.reducer;
