import { Role, UserSex } from ".";
import IDocumento from "./IDocumento";
import IFamiglia, { exampleFamiglia } from "./IFamiglia";

export default interface IAnagrafica {
    Id: number;
    Id_Famiglia: number;
    Nome: string;
    Cognome: string;
    DataNascita: string;
    CF: string;
    Sesso: UserSex;
    Residenza: string;
    Ente: string | null;
    Ruolo: Role;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    IsEducatore: boolean;
    Famiglia: IFamiglia;
    Documenti: IDocumento[];
}

export const exampleAnagrafica: IAnagrafica = {
    Id: 0,
    Id_Famiglia: 0,
    Nome: '',
    Cognome: '',
    DataNascita: '',
    CF: '',
    Sesso: UserSex.NONE,
    Residenza: '',
    Ente: null,
    Ruolo: Role.FIGLIO,
    Creation: '',
    Timestamp: '',

    //* NotMapped
    IsEducatore: false,
    Famiglia: exampleFamiglia,
    Documenti: [],
};