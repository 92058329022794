import IFattoSignificativo, { exampleFattoSignificativo } from "./IFattoSignificativo";
import ITerapia from "./ITerapia";
import IUscita from "./IUscita";

export default interface IGiornata {
    Id: number;
    Id_Famiglia: number;
    Data: string;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    Uscite: IUscita[];
    Terapie: ITerapia[];
    FattiSignificativi: IFattoSignificativo;
}

export const exampleGiornata: IGiornata = {
    Id: 0,
    Id_Famiglia: 0,
    Data: '',
    Creation: '',
    Timestamp: '',

    Uscite: [],
    Terapie: [],
    FattiSignificativi: exampleFattoSignificativo,
};
